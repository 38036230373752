/* Region CustomTextInput */
.custom-text-input-container {
  display: flex;
  align-items: center;
  /* position: relative; */
  appearance: none;
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  padding-top: 8px /* 8px */;
  padding-bottom: 8px /* 8px */;
  border-radius: 2px;
  background-color: transparent;
  height: 48px;
}

.custom-text-input {
  border: none;
  display: flex;
  flex: 1;
  outline: none;
  width: 100%;
  padding-left: 4px;
  background-color: transparent;
}

.error-message {
  width: 100%;
}

.text-input-leading {
  padding-left: 4px;
}

.text-input-trailing {
  padding-left: 12px;
  padding-right: 12px;
}

/* Region End Custom TextInput */

/* Region CustomTextArea */

.custom-text-area-container {
  display: flex;
  align-items: center;
  appearance: none;
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  border-radius: 2px;
  padding-top: 8px /* 8px */;
  padding-bottom: 8px /* 8px */;
  border-radius: 2px;
  background-color: transparent;
}

.custom-text-area {
  border: none;
  display: flex;
  flex: 1;
  outline: none;
  width: 100%;
  padding-left: 4px;
  background-color: transparent;
}

/* Region End CustomTextArea */

/* Region Create CustomDropdown */

/* Region DropDown  */
.custom-dropdown-container {
  position: relative;
  width: 100%;
  height: 48px;
}

.dropdown-button {
  position: relative;
  width: 100%;
  padding: 10px;
  height: 100%;
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  border-radius: 2px;
  cursor: pointer;
  text-align: left;
  color: var(--input-placeholder-color);
}

.dropdown-button.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.dropdown-content-option {
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  background-color: white;
  border: 1px solid #9ca3af;
}
.multi-select-dropdown-content-option {
  position: absolute;
  left: 0;
  width: 100%;
  overflow-y: auto;
  z-index: 1;
  background-color: var(--bg-multi-selected-color);
  border: 1px solid var(--border-multi-selected-color);
}

.multi-dropdown-option {
  overflow-x: auto;
  white-space: nowrap;
}
.option {
  padding: 10px;
  cursor: pointer;
}

/* Region End DropDown  */

/* Region Button  */
.custom-button {
  border-radius: 2px;
  padding: 8px 16px 8px 16px;
}

.custom-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
/* Region End Button  */

/* Region  CustomDropDown With Grouping   */

.custom-dropdown-group {
  position: relative;
  width: 200px;
}
.sticky-label-group {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  font-weight: bold;
  z-index: 1;
}

.selected-value-group {
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
  background-color: #fff;
  position: relative;
}

.selected-value.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.arrow-dropdown-group {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.selected-value.open .arrow {
  transform: translateY(-50%) rotate(180deg);
}

.options-group {
  position: absolute;
  /* top: 100%; */
  /* bottom: 100%; */
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  overflow-y: scroll;

  height: 300px;
}

.group-label-group {
  background-color: #f2f2f2;
  padding: 8px;
  font-weight: bold;
}

.option-group {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding-left: 25px;
}

.option:hover {
  background-color: #f2f2f2;
}

.option.selected {
  background-color: #cce6ff;
}

/* Region End CustomDropDown With Grouping   */

/* Region  custom Model */

.modal-overlay-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-horizontal-line-space {
  margin-left: -1.25rem /* -20px */;
  margin-right: -1.25rem /* -20px */;
}

.modal-custom {
  background-color: white;
  padding: 0px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 99;
}

.title-custom-model {
  margin-inline-end: auto;
}

.custom-modal-div {
  display: flex;
  align-items: center;
}
.close-button-model {
  font-size: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center; /* Horizontally center the logo */
  align-items: center; /* Vertically center the logo */
  padding-top: 10px;
}
.message-custom-model {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.button-container-model {
  display: flex;
  justify-content: center;
}

.cancel-button-model,
.confirm-button-model {
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button-model {
  background-color: #ccc;
}

.confirm-button-model {
  background-color: #007bff;
  color: white;
}
/* Region End custom Model */

/* Region CustomSearchDropDown */

.searchable-dropdown {
  position: relative;
  /* width: 200px; */
}

.dropdown-header-searchable {
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
  background-color: white;
}

.dropdown-options-searchable {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 2;
  border-top: none;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  /* overflow-x: none; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.option-searchable {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option-searchable:hover {
  background-color: #f2f2f2;
}

.search-dropdown-custom-input {
  width: 100%;
  padding: 8px;
  border: none;
  border: 1px solid #9ca3af;
  border-top: 0px;
  outline: none;
  overflow: auto;
}

/* Region end CustomSearchDropDown */

/* Region Date picker  */

/* CustomDatePicker.css */
/* .custom-date-picker {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
}

.date-picker-side-space {
  padding-right: 0.125rem ;
}
.selected-date {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-picker-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.year-list,
.month-list,
.day-grid {
  list-style: none;
  padding: 0;
  margin: 0;
}

.year-list li,
.month-list li,
.day-grid li {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.day-grid li {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.day-grid li.selected {
  background-color: #007bff;
  color: white;


} */

/* Custom Date Picker */
.custom-date-picker {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
  top: 0%;
}

.selected-date {
  cursor: pointer;
  margin-top: 8px;
  margin-left: 4px;
  border: 1px solid white;
  /* border-radius: 4px; */
  position: absolute;
  bottom: 27px;
}

.date-picker-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
}

.year-list,
.month-list,
.day-grid {
  list-style: none;
  padding: 0;
  margin: 0;
}

.year-list li,
.month-list li,
.day-grid li {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.day-grid li {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.day-grid li.selected {
  background-color: #007bff;
  color: white;
}

/* Hide native input */
.hidden-input {
  display: none;
}

/* Region  End Date picker  */

/* Region  File upload */

/* CustomFileUpload.css */
.custom-file-upload {
  display: inline-block;
  font-family: Arial, sans-serif;
  border: 1px solid #9ca3af;
}

.file-upload-container {
  border: 1px solid #9ca3af;
  width: 100%;
}

.file-upload-container-child-div {
  display: flex;
  height: 150px;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}
.file-upload-title-div {
  /* margin-inline-end: auto; */
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: #cce6ff; */
  padding-bottom: 10px;
}
.file-label {
  background-color: var(--primary-color);
}
.my-drag-drop {
  width: 100%;
  margin-inline-end: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(229 231 235);
  border: 1px dashed #9ca3af;
}
.highlight-drag-part {
  background-color: rgb(241, 241, 136);
  border: 1px solid black;
}

.my-drag-drop:hover {
  cursor: pointer;
}
.drag-and-drop-file-upload {
  background-color: #007bff;
}

.info-icon-file-upload {
  display: flex;
  align-items: center;
}
.selected-upload-file-name-div {
  display: flex;
  margin-inline-end: auto;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}
.error-color-file-upload {
  color: red;
}
.selected-file-div {
  display: flex;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  border-width: 1px;
  /* border-color: rgb(243 244 246 / var(--tw-border-opacity)); */
  color: green;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: 1px solid #9ca3af;
  width: 50%;
  text-align: center;
  color: var(--primary-btn-text-color);
}

/* .file-label:hover {
  background-color: #0056b3;
  color: white;
} */

/* Region  End File upload */

/* Region  Tooltip */

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10%; /* Position the tooltip to the right of the element */
  transform: translateY(-50%);
}

.tooltip-container:hover .tooltip-text {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}

.top-tooltip .tooltip-container .tooltip-text {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.right-tooltip .tooltip-container .tooltip-text {
  top: -5px;
  left: 105%;
}

.left-tooltip .tooltip-container .tooltip-text {
  top: -5px;
  right: 105%;
}

/* Region  End Tooltip */

/* Region Tost Message */

.toast-header {
  display: flex;
  justify-content: end;
}
.animate-pulse {
  animation: pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

/* Region End Tost Message  */

/* Region Custom Date/Time Picker */
.ic-date-input-container {
  display: flex;
  align-items: center;
  /* position: relative; */
  border-color: var(--input-inactive-border-color);
  border-width: 1px;
  border-radius: 2px;
  appearance: none;
  /* font-size: 1rem 16px; */
  /* line-height: 1.5rem 24px; */
  padding-top: 8px /* 8px */;
  padding-bottom: 8px /* 8px */;
  padding-left: 10px;
  background-color: transparent;
  height: 48px;
}

.ic-date-input {
  color: transparent !important;
  outline: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  border: none;
  background-color: transparent !important;
  mix-blend-mode: color;
}

.ic-date-input::-webkit-calendar-picker-indicator {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  /* Keep below css might need while problem with other browser experience */
  /* color: transparent !important; */
  /* cursor: pointer; */
  background: transparent;
  /* background-color: transparent !important; */
  /* appearance: none; */
  /* text-decoration: none; */
}
/* Endregion */

/*--Region-FloatingInput-Start-----*/
.floating-input .custom-text-input-container {
  position: relative;
}
.floating-input .custom-text-input-container:focus-within {
  border-color: var(--input-active-border-color);
}

.floating-input label {
  position: absolute;
  background: transparent;
  transition: all 0.3s;
  pointer-events: none;
}

.floating-input .custom-text-input:focus + label,
.floating-input .custom-text-input:not(:placeholder-shown) + label {
  top: 0;
  left: 10px;
  transform: translateY(-60%);
  background: var(--bg-required-label);
  padding: 2px 6px 4px 6px;
  color: var(--input-placeholder-color);
  font-size: 14px;
  line-height: 12px;
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
}

.floating-text-area .custom-text-area-container {
  position: relative;
}
.floating-text-area .custom-text-area-container:focus-within {
  border-color: var(--input-active-border-color);
}

.floating-text-area label {
  position: absolute;
  background: white;
  transition: all 0.3s ease;
  pointer-events: none;
}

.floating-text-area .custom-text-area:focus + label,
.floating-text-area .custom-text-area:not(:placeholder-shown) + label {
  top: 0;
  left: 10px;
  transform: translateY(-60%);
  padding: 2px 6px 4px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
  color: var(--input-placeholder-color);
  font-size: 14px;
  line-height: 16px;
}

.floating-dropDown-label {
  /* position: absolute; */
  pointer-events: none;
  padding: 2px 6px 4px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
  left: 8px;
  top: 0;
  transition: all 0.3s;
}

.floating-timePicker {
  pointer-events: none;
  left: 8px;
  transform: translateY(-50%);
  padding: 2px 6px 2px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
  transition: all 0.3s ease;
}

.floating-datePicker {
  pointer-events: none;
  left: 8px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  padding: 2px 6px 2px 6px;
  background: var(--bg-required-label);
  border-radius: 10px;
  border: 0.5px solid #dcdcdc;
}
/*--Region-FloatingInput-End-----*/
.select .selectBtn {
  background: var(--bg1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
}

.select .selectDropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  background: var(--bg1);
  border-top: 1px solid #eee;
  z-index: 1;
  background: #fff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  visibility: hidden;
  transition: 0.2s ease;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}
.select .selectDropdown .option {
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.select .selectDropdown .option:hover {
  background: #f8f8f8;
}
.select .selectDropdown.toggle {
  visibility: visible;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.select .selectBtn {
  background: var(--bg1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
}
.select .selectBtn:after {
  content: "";
  position: absolute;
  top: 45%;
  right: 15px;
  width: 6px;
  height: 6px;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  transition: 0.2s ease;
}
.select .selectBtn.toggle {
  border-radius: 3px 3px 0 0;
}
.select .selectBtn.toggle:after {
  -webkit-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
}

.option {
  padding: 10px;
  cursor: pointer;
}
.main_border {
  border: 1px solid #9ca3af;
  border-radius: 5px;
  padding: 10px;
}
/* Slide in animation */
@keyframes slideIn {
  from {
    transform: translateX(60%);
  }
  to {
    transform: translateX(0);
  }
}

/* Slide out animation */
@keyframes slideOut {
  from {
    transform: translateX(60%);
  }
  to {
    transform: translateX(0%);
  }
}

.slide-in {
  animation: slideIn 0.3s forwards;
}

.slide-out {
  animation: slideOut 0.5s forwards;
}
.bottom-side {
  bottom: -450px;
}

.ic-date-input::-webkit-calendar-picker-indicator {
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  /* Keep below css might need while problem with other browser experience */
  color: transparent !important;
  cursor: pointer;
  background: transparent;
  /* background-color: transparent !important; */
  /* appearance: none; */
  /* text-decoration: none; */
}
.ic-date-input {
  color: transparent !important;
  outline: none;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  border: none;
  background-color: transparent !important;
  mix-blend-mode: color;
}

.react-responsive-modal-modal {
  width: 590px;
}

.cursor:hover {
  cursor: pointer;
}

.readonly-tag {
  display: inline-block;
  background-color: #3b82f6;
  color: #f1f1f1;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 20px;
}

/* Region Skeleton */
.table-value-skeleton {
  border-radius: 4px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}

fieldset.pricing-sec {
  margin: 25px;
  border: 1px solid lightgrey;
  padding-top: 25px;
  color: var(--input-label-color);
  background: #fbfbfb;
}

.setting-switch {
  border: 1px solid var(--input-inactive-border-color);
  margin-bottom: 25px;
  padding: 12px;
}
/* table delete css */
.isDelete {
  background-color: var(--bg-tableIsDelete-color);
}
.isDelete:hover {
  background-color: var(--bg-hover-tableDelete-color);
}

/* trainer application form */
.key-code {
  font-size: 60px;
  letter-spacing: 10px;
}
.trainer-form-accordion-border {
  border: 1px solid var(--accordion-border-color);
}
.timeline-border-color {
  border: var(--bg-sidebar-menu-color);
}

.approve-fill-color {
  fill: var(--approve-btn-color);
}
.reject-fill-color {
  fill: var(--reject-btn-color);
}

.approve-button-color {
  background-color: var(--approve-btn-color);
}
.reject-button-color {
  background-color: var(--reject-btn-color);
}

.full-height-minus-header {
  min-height: calc(100vh - 56px);
}

.full-height {
  height: calc(100vh - 56px);
  overflow: hidden;
}

.img-list {
  width: 280px;
  height: 150px;
  cursor: zoom-in;
  margin: 10px;
  display: flex;
  align-items: center;
}

.img-zoom {
  cursor: -webkit-grab;
  position: fixed;
  top: 25%;
  left: 27%;
  z-index: 999;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0, 1) 0s;
  transform: translate(180.39999px, 100.2px) scale(3);
  overflow: visible;
}
.img-div {
  z-index: 998;
  background-color: var(--background-color);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.grievance-message-input {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.grievance-message-text-color {
  color: var(--input-placeholder-color);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* tag class */
.tag-btn {
  padding-left: 64px !important;
}

.table-scroll {
  scrollbar-width: none;
}

.form-modal-wrapper-transform {
  animation-name: from-model-animation;
  animation-duration: 0.1s;
}

@keyframes from-model-animation {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}
/* scrollbar remove */

.scrollbar-remove {
  scrollbar-width: none;
}

/* src/UnauthorizedAccess.css */
.unauthorized-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: "Arial", sans-serif;
}

.unauthorized-content {
  text-align: center;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.unauthorized-content h1 {
  font-size: 2.5rem;
  color: #000;
}

.unauthorized-content p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.logout-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #2563eb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #749df7;
}

.app-versions {
  background: #e3e1e1;
  padding: 5px 10px 10px 13px;
  margin-top: 10px;
}

.editor-border {
  border: 1px solid #c3c3c3;
  margin-bottom: 10px;
}

.editor-bottom-border{
  border-bottom: 1px solid #c3c3c3;
}