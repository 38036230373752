.typo-input-value {
  color: var(--input-value-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 21px;
}

.typo-input-label {
  color: var(--input-label-color);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 17px;
}

.typo-input-placeholder::placeholder {
  color: var(--input-placeholder-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 20px;
}

.typo-error {
  color: var(--input-error-color);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 17px;
}

.error-border {
  border-color: var(--input-error-color);
  border-width: 1px solid;
}

.typo-table-header {
  color: var(--table-header-text-color);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 20px;
}

.typo-table-value {
  color: var(--table-value-text-color);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.15px;
  line-height: 17px;
}

.typo-status {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 17px;
}

.typo-form-title {
  color: var(--on-background-color);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 23px;
}

.typo-btn-primary {
  color: var(--primary-btn-text-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 20px;
}

.typo-floating-label {
  color: var(--input-placeholder-color);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 18px;
}

.typo-floating-dropDown-label {
  color: var(--input-placeholder-color);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  /* line-height: 20px; */
}

.typo-placeholder-floating {
  color: var(--input-placeholder-color);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  /* line-height: 20px; */
}

.typo-display-large {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 57px;
  letter-spacing: 0px;
}

.typo-display-medium {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 45px;
  letter-spacing: 0px;
}

.typo-display-small {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 36px;
  letter-spacing: 0px;
}

.typo-header-large {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0px;
}

.typo-header-medium {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 28px;
  letter-spacing: 0px;
}

.typo-header-small {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0px;
}

.typo-title-large {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0px;
}

.typo-title-medium {
  color: var(--text-color-base);
  font-weight: 350;
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 21px;
}

.typo-title-small {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.typo-body-large {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.typo-body-medium {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.typo-body-small {
  color: var(--text-color-base);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

@media screen and (max-width: 1366px) {
  .typo-header-large {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 768px) {
  .typo-display-large {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 50px;
    letter-spacing: 0px;
  }

  .typo-display-medium {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 38px;
    letter-spacing: 0px;
  }

  .typo-display-small {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0px;
  }

  .typo-header-large {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 26px;
    letter-spacing: 0px;
  }

  .typo-header-medium {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0px;
  }

  .typo-header-small {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0px;
  }

  .typo-title-large {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0px;
  }

  .typo-title-medium {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
  }

  .typo-title-small {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
  }

  .typo-body-large {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .typo-body-medium {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
  }

  .typo-body-small {
    color: var(--text-color-base);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
  }
}
