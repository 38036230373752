@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  :root {
    --text-color-base: #262626;
    --text-color-primary: #3229d3;
    --bg-color-primary: #d15858;
    --color-error: red;

    --primary-color: #2563eb;
    --background-color: #ffffff;
    --on-primary-color: #ffffff;
    --on-background-color: #000000;
    --error-color: #b00020;
    --disabled-color: #d7d7d9;

    --switch-active-color: #2563eb;
    --switch-inative-color: #e5e7eb;
    --switch-cap-color: #ffffff;
    --radio-active-color: #2563eb;

    --primary-btn-color: #2563eb;
    --primary-btn-text-color: #ffffff;
    --negative-btn-color: #cccccc;
    --negative-btn-text-color: #000000;

    --table-base-color: #f1f5f9;
    --table-surface-color: #ffffff;
    --table-hover-color: #f0eff4;
    --table-border-color: #f0f0f0;
    --table-active-page-color: #f0eff4;
    --table-header-text-color: #000000;
    --table-value-text-color: #262626;

    --input-label-color: #262626;
    --input-placeholder-color: #666666;
    --input-value-color: #262626;
    --input-active-border-color: #2563eb;
    --input-inactive-border-color: #a4a3a3;
    --input-error-color: #d93434;

    --form-color: #ffffff;
    --form-footer-color: #e5e7eb;
    --form-footer-border-color: #d5d6d7;
    --form-footer-text-color: #262626;

    --tooltip-color: #262626;
    --tooltip-text-color: #ffffff;
    --bg-floating-color: #ffffff;

    --bg-time-line-color: #4b5563;
    --bg-grievance-admin-message-color: #00aff0;
    --bg-grievance-admin-user-color: #ebecee;
    --text-color-grievance: #333333;
  }

  .Light {
    --text-color-base: #262626;
    --text-color-primary: #e7e7e7;
    --bg-color-primary: rgba(37 99 235);
    --color-error: red;

    /* required label color */
    --bg-required-label: #f7f7f7;

    /* library */
    --text-navigation-bar-color: #6b7280;
    --text-navigation-next-bar-color: #3b82f6;
    --text-navigation-bar-arrow-color: #030712;

    /* FAQs */
    --bg-faqs-color: #f9fafb;
    --border-faqs-color: #d1d5db;
    --text-faqs-color: #111827;
    --ring-faqs-color: #3b82f6;
    --border-faqs-focus-color: #3b82f6;

    /* serviceCategory */
    --bg-serviceCategory-color: #f9fafb;
    --border-serviceCategory-color: #d1d5db;
    --text-serviceCategory-color: #111827;
    --ring-serviceCategory-color: #3b82f6;
    --border-serviceCategory-focus-color: #3b82f6;

    /* menuItems */
    --text-menu-item-color: #6b7280;

    /* Library */
    --bg-library-color: #f9fafb;
    --bg-library-tag-color: #3b82f6;
    --border-library-color: #d1d5db;
    --text-library-color: #111827;
    --text-library-box-color: #f7f7f7;
    --ring-library-color: #3b82f6;
    --border-library-focus-color: #3b82f6;

    /* react tag */
    --bg-react-tag-color: #f9fafb;
    --bg-react-tag-box-color: #3b82f6;
    --border-react-tag-color: #d1d5db;
    --text-react-tag-color: #111827;
    --text-react-tag-box-color: #f7f7f7;
    --ring-react-tag-color: #3b82f6;
    --border-react-tag-focus-color: #3b82f6;

    /* additionalInfo */
    --bg-additional-info-color: #f9fafb;
    --border-additional-info-color: #d1d5db;
    --text-additional-info-color: #111827;
    --ring-additional-info-color: #3b82f6;
    --border-additional-info-focus-color: #3b82f6;

    /* basicInfo */
    --bg-basic-info-color: #f9fafb;
    --border-basic-info-color: #d1d5db;
    --text-basic-info-color: #111827;
    --ring-basic-info-color: #3b82f6;
    --border-basic-info-focus-color: #3b82f6;

    /* CourseContent */
    --bg-course-content-color: #3b82f6;
    --text-course-content-color: #f7f7f7;

    /* serviceFAQs */
    --text-service-faqs-color: #f7f7f7;
    --bg-service-faqs-color: #3b82f6;
    --border-service-faqs-color: #d1d5db;
    --ring-service-faqs-color: #93c5fd;

    /* addService */
    --border-add-service-color: #d1d5db;
    --border-select-add-service-color: #3b82f6;
    --text-add-service-color: #374151;

    /* date picker */
    --text-date-picker-color: #a1a1aa;
    /* time picker */
    --text-time-picker-color: #a1a1aa;

    /* file Upload */
    --bg-file-upload-color: #f9fafb;

    /* IctTable */
    --bg-after-ict-color: #ffffff;
    --bg-ict-table-switch-color: #e5e7eb;
    --bg-checked-ict-color: #2563eb;
    --bg-ict-status-color: #e9d5ff;
    --ring-ict-color: #93c5fd;
    --ring-dark-focus-ict-color: #1e40af;
    --bg-dark-ict-color: #374151;
    --border-after-ict-color: #d1d5db;
    --border-dark-ict-color: #4b5563;
    --bg-tableIsDelete-color: #ffe8e8;
    --bg-hover-tableDelete-color: #fdcfcf;
    /* search dropdown */
    --text-search-dropdown-color: #111827;

    /*  advanceSearch   */
    --bg-advance-search-btn-color: #ffffff;
    --bg-advance-search-color: #e4e4e7;
    --advance-search-text-color: #ffffff;
    --advance-search-text-label-color: #374151;
    --search-icon-color: #6b7280;
    --primary-btn-color: #2563eb;
    /* sidebarMenu */
    --text-sidebar-menu-color: #111827;
    --bg-sidebar-menu-color: #e5e7eb;
    /* Login */
    --text-login-color: #374151;
    --text-login-message-color: #4b5563;

    /* Otp verify */
    --bg-otp-verify-color: #efefef85;
    --bg-otp-verify-btn-color: #3b82f6;
    --text-otp-verify-btn-color: #ffffff;

    /* profile modal  */
    --bg-profile-color: #ffffff;
    --profile-text-color: #757575;

    /* home */
    --bg-home-color: #fafafa;
    --border-home-color: #d1d5db;
    /* tab bar system setting */
    --bg-tab-color: #f7f7f7;
    --text-tab-color: #334155;
    --bg-active-tab-color: #ffffff;

    /* multiSelected */
    --bg-multi-selected-color: #f9fafb;
    --border-multi-selected-color: #d1d5db;
    --text-multi-selected-color: #111827;
    /* accordion  */
    --accordion-border-color: #e5e7eb;
    --approve-btn-color: #16a34a;
    --reject-btn-color: #ef4444;

    /* system configuration */
    --bg-system-color: #ffffff;

    /* grievance */
    --bg-grievance-admin-message-color: #00aff0;
    --bg-grievance-admin-user-color: #ebecee;
    --text-color-grievance: #333333;

    /* Booking view */
    --bg-booking-view-color: #f3f4f6;
    --border-booking-view-color: #d1d5db;

    /* About Part */
    --text-about-link-color: #3229d3;
  }

  .Dark {
    --text-color-primary: ;
    --bg-color-primary: #620303;
    --color-error: red;
    --bg-time-line-color: #4b5563;
  }
}

* {
  margin: 0;
  font-family: sans-serif;
  font-family: "Segoe UI", sans-serif;
}

@layer components {
  .btn-class {
    @apply text-gray-600 rounded-sm px-3 py-2 text-sm font-medium;
  }
  .form-modal-right {
    @apply fixed top-0 right-0 z-40 h-screen border shadow-md overflow-y-auto transition-transform bg-skin-form w-80 flex flex-col justify-between;
  }
  .switch-input {
    @apply w-9 h-5 cursor-pointer bg-skin-switch-inactive rounded-full peer-checked:after:translate-x-full after:absolute after:top-[2px] after:left-[2px] after:bg-skin-switch-cap after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-skin-switch-active;
  }
  .action-view {
    @apply w-5 transform hover:scale-110 cursor-pointer;
  }
  .services-input {
    @apply text-sm rounded-lg  block  p-2.5;
  }
}
