.bg-image {
  background-image: url("../assets/image/Log-in_banner.jpg");
}

/* navbar dropdown css */
.menu-items button {
  text-align: left;
  padding: 0.5rem 0.8rem;
}
.menu-items a {
  text-align: left;
  padding: 0.5rem 0.8rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #e3e3e3;
}

.hover-class-start:hover {
  background-color: #e3e3e3;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-right: auto;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}
.dropdown {
  position: absolute;
  right: 0;
  left: 0;
  border: 1px solid #e3e3e3;
  /* box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
     0px 0px 6px -2px rgba(71, 63, 79, 0.16); */
  font-size: 0.875rem;
  z-index: 9999;
  /* min-width: 10rem; */
  width: 14rem;
  padding: 0rem 0 0 0;
  list-style: none;
  /* padding-top: 9px; */
  background-color: #fff;
  border-radius: 0.2rem;
  display: none;
}
.dropdown.show {
  display: block;
  background-color: #fff;
}
.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 0px;
  background-color: #fff;
}
.custom-shadow-pin {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 3px 1px;
}

/* MemberOin model */

/* App.css */
.modal-member-pin {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;

  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-member-pin {
  background-color: white;
  width: 100%;
  /* height: 100%; */
  /* overflow-x: scroll; */
  max-width: 800px; /* You can adjust the max width as needed */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-top: 20px;
  position: relative;
}
.shadow-model {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.close-button-member-pin {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.member-count {
  bottom: 19px;
  left: 13px;
}

/* Define the circular loading spinner animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Define the styling for the spinner element */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  text-align: center;
  animation: rotate 1s linear infinite;
}

/* login button css 28-09 */

.container {
  align-items: center;
  background: #2563eb;
  border-radius: 4px;
  /* / box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); / */
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  /* width: 200px;  */
}
.text {
  color: white;
  position: absolute;
  transition: opacity 300ms;
  user-select: none;
  -moz-user-select: none;
}
.fingerprint {
  /* / height: 80px; / */
  left: -8px;
  opacity: 0;
  position: absolute;
  stroke: #777;
  top: -9px;
  transition: opacity 1ms;
}
.fingerprint-active {
  stroke: #fff;
}
.fingerprint-out {
  opacity: 1;
}
.odd {
  stroke-dasharray: 0px 50px;
  stroke-dashoffset: 1px;
  transition: stroke-dasharray 1ms;
}
.even {
  stroke-dasharray: 50px 50px;
  stroke-dashoffset: -41px;
  transition: stroke-dashoffset 1ms;
}
.ok {
  opacity: 0;
}
.active.container {
  animation: 1.5s Container;
}
.active .text {
  opacity: 0;
  animation: 3s Text forwards;
}
.active .fingerprint {
  opacity: 1;
  transition: opacity 300ms 200ms;
}
.active .fingerprint-base .odd {
  stroke-dasharray: 50px 50px;
  transition: stroke-dasharray 800ms 100ms;
}
.active .fingerprint-base .even {
  stroke-dashoffset: 0px;
  transition: stroke-dashoffset 800ms;
}
.active .fingerprint-active .odd {
  stroke-dasharray: 50px 50px;
  transition: stroke-dasharray 2000ms 1500ms;
}
.active .fingerprint-active .even {
  stroke-dashoffset: 0px;
  transition: stroke-dashoffset 2000ms 1300ms;
}
.active .fingerprint-out {
  opacity: 0;
  transition: opacity 300ms 4100ms;
}
.active .ok {
  opacity: 1;
  animation: 3s Ok forwards;
}
@keyframes Container {
  0% {
    transform: scaleX(1);
    transform-origin: center;
    background: #8e8b8b;
  }

  50% {
    transform: scaleX(0.3);
    transform-origin: center;
    background: #8e8b8b;
  }

  100% {
    transform: scaleX(1);
    transform-origin: center;
    background: #8e8b8b;
  }
}

@keyframes Text {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  6% {
    opacity: 0;
    transform: scale(0.5);
  }

  94% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes Ok {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  77% {
    opacity: 1;
    transform: scale(1);
  }
  92% {
    opacity: 1;
    transform: scale(1);
  }
  96% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 0;
  }
}

.rotate {
  transform: rotate(0deg); /* Rotate the element by 45 degrees */
  transition: transform 0.3s; /* Add a smooth transition effect */
}

.rotate-res {
  transform: rotate(-40deg); /* Rotate the element by 45 degrees */
  transition: transform 0.3s; /* Add a smooth transition effect */
}

/* --
------------------------------------------------- */

/* / Slider.css / */

/* / Add your slide animation / */
.animated-slide {
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

/* Pinbar functionality  */

.horizontal-scroll-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Content inside the scrollable container */
.horizontal-scroll-content {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease-in-out;
}

/* Individual items in the list */

/* Scroll buttons */
.scroll-button {
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* background-color: rgb(34, 32, 32); */
  /* color: white; */
  /* padding: 2px; */

  /* border: 1px solid black; */
  /* cursor: pointer; */
  /* z-index: 1; */
}

.scroll-button {
  right: 17;
}

.scroll-button.right {
  right: 0;
}

/* Add this CSS to your stylesheet or CSS file */
.open-animation {
  max-height: 70px;
  transition: max-height 0.5s ease-in-out;
  overflow-y: hidden;
}

.closed-animation {
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
  overflow-y: hidden;
}

/*------------------------------------------------------------------ custom scrollbar----------------------- */
body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* ----------------------------------------------------------------------------------------------------------- */

/* ----------------------------------table css--------------------------------------------------------------  */

#datatable table {
  /* border: 1px solid #ccc; */
  /* border-collapse: collapse; */
  width: 100%;
  /* table-layout: fixed; */
}

@media screen and (max-width: 991px) {
  #datatable table {
    border: 0;
  }

  #datatable table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #datatable table tr {
    /* border-bottom: 3px solid #ddd; */
    display: block;
    /* margin-bottom: .625em; */
  }

  #datatable table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  #datatable table td::before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  #datatable table td:last-child {
    border-bottom: 0;
  }
}

.custom-table {
  min-width: 1200px;
}
/* ----------------------------------table css--------------------------------------------------------------  */

/* ----------------------------------------Data-list Dropdown list table----------------------------------------- */

#Dropdowntable table {
  border: 0;
  width: 100%;
  /* width: 100%;
  table-layout: auto; */
}

#Dropdowntable table thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#Dropdowntable table tr {
  border-bottom: 1px solid #ddd;
  display: block;
  /* margin-bottom: .625em; */
}

#Dropdowntable table td {
  border-bottom: 1px solid #ddd;
  display: block;
  font-size: 0.8em;
  text-align: right;
}

#Dropdowntable table td::before {
  /*
  * aria-label has no advantage, it won't be read inside a table
  content: attr(aria-label);
  */
  content: attr(data-label);
  float: left;
  font-weight: bold;
  text-transform: uppercase;
}

#Dropdowntable table td:last-child {
  border-bottom: 0;
}

/* ----------------------------------------Data-list Dropdown list table End----------------------------------------- */

.customleftshadow {
  box-shadow: rgb(0 0 0 / 8%) -6px 0px 5px 0px;
}

.customshadowallside {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 2px 0px;
}

/* ------------------- */

.open-sidebar {
  max-width: 200px;
  /* transition: max-width 0.5s ease-in-out !important; */
  transition: max-width 0.6s ease-in-out;
  /* overflow-y: hidden; */
}

.closed-sidebar {
  max-width: 0;
  /* transition: max-width 0.4s ease-in-out !important; */
  /* transition: margin-left 5.5s ease-in-out; */
  /* overflow-y: hidden; */
}

/* DataList.css */

.hovered-row {
  opacity: 0.7; /* Adjust the opacity value as needed */
  position: relative;
}

.hovered-row:hover {
  opacity: 1;
}

.hovered-row:hover .action-buttons {
  visibility: visible;
}

.action-buttons {
  visibility: hidden;
  position: absolute;
  top: 50%; /* Adjust the vertical position of the action buttons */
  left: 0; /* Adjust the horizontal position of the action buttons */
  transform: translate(0, -50%);
}

/*---------------------------------------------------- floating label-------------------------------------------- */

.did-floating-label-content {
  position: relative;
  margin-bottom: 20px;
}
.did-floating-label {
  color: #1e4c82;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 8px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.did-floating-input,
.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #3d85d8;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    ~ .did-floating-label {
      top: -8px;
      font-size: 13px;
    }
  }
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}
.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top: 11px;
  font-size: 13px;
}
.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input {
  .did-floating-input,
  .did-floating-select {
    border: 2px solid #9d3b3b;
    color: #9d3b3b;
  }
  .did-floating-label {
    font-weight: 600;
    color: #9d3b3b;
  }
  .did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
  }
}

.input-group {
  display: flex;
  .did-floating-input {
    border-radius: 0 4px 4px 0;
    border-left: 0;
    padding-left: 0;
  }
}
.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}
.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 34px;
  color: #323840;
  padding: 0 5px 0 20px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #3d85d8;
  border-radius: 4px 0 0 4px;
  border-right: none;
}
.pricing-sec {
  padding: 15px;
  border-radius: 0 0 6px 6px;
}
.pricing-sec legend {
  width: initial;
}
.select-card-sec {
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 14%);
}

.setting-card-sec {
  border-radius: 6px 6px 0 0;
}
.setting-card-bottom {
  border-radius: 0 0 6px 6px;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  background: #fff;
}
/* -------------------------------------------------------------------------------------------------------------- */

.chart-card {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
